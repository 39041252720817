@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;1,400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-radius: 0;
}
